/**
 * External dependencies
 */
import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";

/**
 * Internal dependencies
 */
import "./styles/_index.scss";
import GetURL from "../../components/common/site/get-url"
const Button = ({
  children,
  className,
  larger,
  color = "blue",
  borderColor = '',
  textColor = '',
  tel,
  href = '/',
  displayAsLink = true,
  isExternalLink = false,
  onClick,
  label,
  link,
  id
}) => {
  const classes = classNames(
    `btn btn--${color} d-flex align-items-center justify-content-center
    ${borderColor && ('border-color-' + borderColor)} 
    ${textColor && ('text-color-' + textColor)} 
    `,
    className,
    {
      "is-larger": larger,
    }
  );

  if (tel) {
    return <a href={`tel:${tel}`} className={classes}>{children}</a>
  }

  return (
    displayAsLink
      ? (
        isExternalLink
          ?
          <Link target={"_blank"} className={classes} to={link} >{children}</Link>
           
          : link ?
          <Link className={classes} to={link} >{children}</Link>
          : 
          <GetURL
          class={classes}
          label={""}
          alias={href}
          onClick={onClick}
        >{children}</GetURL>
      )
      : <button id={id} className={classes} onClick={onClick}>{children}</button>
  );
}

export default Button;

const ButtonText = ({ className, children }) => <div className={classNames('btn__text', className)}>{children}</div>;
const ButtonIcon = ({ className, children }) => <div className={classNames('btn__icon', className)}>{children}</div>;

Button.Text = ButtonText;
Button.Icon = ButtonIcon;
